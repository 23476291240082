import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`All Uniswap V2 pool tokens support meta-transaction approvals via the `}<Link to='/docs/honeyswap/smart-contracts/pair-erc-20#permit' mdxType="Link">{`permit`}</Link>{` function. This obviates the need for a blocking approve transaction before programmatic interactions with pool tokens can occur.`}</p>
    <h1 {...{
      "id": "erc-712",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#erc-712",
        "aria-label": "erc 712 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ERC-712`}</h1>
    <p>{`In vanilla ERC-20 token contracts, owners may only register approvals by directly calling a function which uses `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` to permission itself. With meta-approvals, ownership and permissioning are derived from a signature passed into the function by the caller (sometimes referred to as the relayer). Because signing data with Ethereum private keys can be a tricky endeavor, Uniswap V2 relies on `}<a parentName="p" {...{
        "href": "https://eips.ethereum.org/EIPS/eip-712"
      }}>{`ERC-712`}</a>{`, a signature standard with widespread community support, to ensure user safety and wallet compatibility.`}</p>
    <h2 {...{
      "id": "domain-separator",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#domain-separator",
        "aria-label": "domain separator permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Domain Separator`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`keccak256(
  abi.encode(
    keccak256('EIP712Domain(string name,string version,uint256 chainId,address verifyingContract)'),
    keccak256(bytes(name)),
    keccak256(bytes('1')),
    chainId,
    address(this)
  )
);
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`name`}</inlineCode>{` is always `}<inlineCode parentName="li">{`Uniswap V2`}</inlineCode>{`, see `}<Link to='/docs/honeyswap/smart-contracts/pair-erc-20#name' mdxType="Link">{`name`}</Link>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`chainId`}</inlineCode>{` is determined from the `}<a parentName="li" {...{
          "href": "https://ethereum-magicians.org/t/eip-1344-add-chain-id-opcode/1131"
        }}>{`ERC-1344`}</a>{` `}<inlineCode parentName="li">{`chainid`}</inlineCode>{` opcode.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`address(this)`}</inlineCode>{` is the address of the pair, see `}<Link to='/docs/honeyswap/technical-considerations/pair-addresses' mdxType="Link">{`Pair Addresses`}</Link>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "permit-typehash",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#permit-typehash",
        "aria-label": "permit typehash permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Permit Typehash`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`keccak256('Permit(address owner,address spender,uint256 value,uint256 nonce,uint256 deadline)');\`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      